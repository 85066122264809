<template>
  <div v-if="dealer && !loading" class="world cart" :class="{ 'confirm': orderComplete }">
    <div v-if="orderComplete" class="confirmation">
      <h1>{{ $t('Je bestelling werd geplaatst!') }}</h1>

      <img width="53" src="@/assets/img/shopping-bag.svg" alt="Shopping bag">

      <div class="info" v-html="getTranslation(globals.order_confirmation).value"></div>
      <router-link :to="{name: 'ww.dashboard'}" class="btn">{{ $t('Terug naar Wilms World') }}</router-link>
    </div>
    <template v-else>
      <div class="wrapper-title">
        <div class="container">
          <router-link :to="{name: 'ww.products'}" class="text-wilmsLightBlue font-100 pb-4 block">&larr; {{ $t('Producten overzicht') }}</router-link>
          <h1>{{ $t('Winkelwagen') }}</h1>
          <div class="intro-text" v-if="globals.intro_text">
            <div v-html="getTranslation(globals.intro_text).value"></div>
          </div>
        </div>
      </div>

      <template v-if="products.length > 0">
        <div class="cart-wrapper">
          <div class="container">
            <div class="table-wrapper cart">
              <div class="table-element">
                <table>
                  <thead>
                  <tr>
                    <th>{{ $t('Product') }}</th>
                    <th>{{ $t('Prijs') }}</th>
                    <th>{{ $t('Aantal') }}</th>
                    <th>{{ $t('Totaal') }}</th>
                    <th class="delete">&nbsp;</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="product in products" :key="product.id">
                    <td>{{ getTranslation(product).name }}</td>
                    <td>{{ $n(product.unit_price, 'currency', 'nl-BE') }}</td>
                    <td>
                      <div class="amount-wrapper">
                        <div class="amount-input">
                          <button :class="{ 'disabled': product.amount === product.minimal_total_order_amount }" class="min" @click="minAmount(product)">
                            <img alt="" src="@/assets/img/min.svg"></button>
                          <div class="amount">{{ product.amount }}</div>
                          <button class="plus" @click="plusAmount(product)"><img alt="" src="@/assets/img/plus.svg">
                          </button>
                        </div>
                        <div class="minimum">{{ $t('Min.') }} {{ product.minimal_total_order_amount }}</div>
                      </div>
                    </td>
                    <td>{{ $n(product.total, 'currency', 'nl-BE') }}</td>
                    <td class="delete" @click="deleteProduct(product)"><img src="@/assets/img/close.svg"></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="total-wrapper">
            <div class="icon">
              <img alt="Calendar" src="@/assets/img/budget-icon-blue.svg">
            </div>

            <div class="totals">
              <div class="subtotal">
                <div class="label">{{ $t('Subtotaal') }}</div>
                <div class="value">{{ $n(shoppingCartTotal, 'currency', 'nl-BE') }}</div>
              </div>

              <div class="total">
                <div class="label">{{ $t('Totaal incl. verwerkingskosten') }}</div>
                <div class="value">{{ $n(shoppingCartTotal + 25, 'currency', 'nl-BE') }}</div>
              </div>

              <div class="total">
                <div class="label">{{ $t('Betaling via marketingbudget') }}</div>
                <div class="value">{{ $n(budgetToPay, 'currency', 'nl-BE') }}</div>
              </div>

              <div class="total">
                <div class="label">{{ $t('Saldo te betalen via factuur') }}</div>
                <div class="value">{{ $n(invoiceToPay, 'currency', 'nl-BE') }}</div>
              </div>
            </div>

            <div class="order-wrapper">
              <button class="btn btn-confirm" @click="confirmOrder">{{ $t('Bestelling bevestigen') }}
                <img alt="" src="@/assets/img/arrow_grey.svg"></button>
            </div>
          </div>
        </div>

        <div class="info-wrapper">
          <div class="container">
            <img src="@/assets/img/info-icon.svg" alt="">
            <div v-html="getTranslation(globals.info).value"></div>
          </div>
        </div>
      </template>
      <div v-else class="cart-empty">
        {{ $t('Er zijn momenteel nog geen producten in je winkelwagen') }}
      </div>
    </template>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'

export default {
  data () {
    return {
      loading: true,
      products: [],
      shoppingCart: {},
      shoppingCartTotal: 0,
      budgetToPay: this.shoppingCartTotal,
      invoiceToPay: 0,
      orderComplete: false,
      globals: {}
    }
  },
  async mounted () {
    await this.setDealer()
    this.getShoppingCart()

    await this.getProducts()
    this.calculateTotal()

    this.globals.order_confirmation = (await ApiService.fetchGlobal('products', 'order.confirmation')).data
    this.globals.info = (await ApiService.fetchGlobal('products', 'order.info')).data
    this.globals.intro_text = (await ApiService.fetchGlobal('products', 'order.intro')).data

    this.loading = false
  },
  methods: {
    async confirmOrder () {
      const res = await ApiService.createOrder(this.dealer.id, { products: this.shoppingCart })

      if (res.status === 200) {
        this.shoppingCart = {}
        localStorage.setItem('shopping-cart', JSON.stringify(this.shoppingCart))
        this.products = []
        this.shoppingCartTotal = 0
        this.orderComplete = true
      }
    },
    async getProducts () {
      for (const productId in this.shoppingCart) {
        const res = await ApiService.fetchProduct(productId)
        const product = res.data

        if (res.data) {
          product.amount = this.shoppingCart[productId]
          product.total = product.unit_price * product.amount
          this.products.push(res.data)
        } else {
          delete this.shoppingCart[productId]
          localStorage.setItem('shopping-cart', JSON.stringify(this.shoppingCart))
        }
      }
    },
    deleteProduct (product) {
      delete this.shoppingCart[product.id]
      localStorage.setItem('shopping-cart', JSON.stringify(this.shoppingCart))
      const index = this.products.findIndex(p => p.id === product.id)
      this.products.splice(index, 1)
      this.calculateTotal()
    },
    calculateTotal () {
      this.shoppingCartTotal = 0
      for (const product of this.products) {
        this.shoppingCartTotal += product.total
      }
      this.budgetToPay = this.shoppingCartTotal + 25
      this.invoiceToPay = 0

      if ((this.shoppingCartTotal + 25) > this.dealer.b2b_order_budget) {
        this.invoiceToPay = Math.abs(this.dealer.b2b_order_budget - (this.shoppingCartTotal + 25))
        this.budgetToPay = this.dealer.b2b_order_budget
      }
    },
    minAmount (product) {
      if (product.amount > product.minimal_total_order_amount) {
        product.amount -= product.minimal_order_amount
        product.total = product.amount * product.unit_price
      }

      this.shoppingCart[product.id] = product.amount
      localStorage.setItem('shopping-cart', JSON.stringify(this.shoppingCart))
      this.calculateTotal()
    },
    plusAmount (product) {
      product.amount += product.minimal_order_amount
      product.total = product.amount * product.unit_price

      this.shoppingCart[product.id] = product.amount
      localStorage.setItem('shopping-cart', JSON.stringify(this.shoppingCart))
      this.calculateTotal()
    },
    getShoppingCart () {
      this.shoppingCart = JSON.parse(localStorage.getItem('shopping-cart')) || {}
    }
  }
}
</script>
